import styled , { css }from 'styled-components';
import { StyledInput } from '../../CreateEvent/styles';
import { DropdownItem, DropdownButton, Dropdown } from 'react-bootstrap';

const CustomInput = styled(StyledInput)`
  ${'' /* border-radius: 6px; */}
  ${'' /* background: #F7F5EB80; */}
  ${'' /* border: 1px solid #005C874D; */}
  border: ${({ isBorder }) => isBorder ? isBorder : ' 1px solid #005C874D'};
  border-radius: ${({ isRadius }) => isRadius ? isRadius : '6px'};
  background: ${({ isBgColor }) => isBgColor ? isBgColor : '#F7F5EB80'};
  height: ${({ isHeight }) => isHeight ? isHeight : '60px'};
  ${'' /* font-family:"rubik-medium"; */}
  font-family: ${({ isFont }) => isFont ? isFont : 'rubik-medium'};
  font-size: 16px;
  line-height:24px;
  padding: 18px 12px;  
  ${'' /* color:#005C87; */}
  color: ${({ disabled }) => disabled ? 'rgba(0, 92, 135, 0.6)'  : '#005C87'};
  margin-top: ${({ isMargin }) => isMargin ? isMargin : '0px'};
  >input{
    height: ${({ isHeight }) => isHeight ? isHeight : '60px'};
  }
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    font-family:"rubik";
    font-size: 16px;
    line-height:24px;
    ${'' /* color:red; */}
    color: ${({ isPlaceholder }) => isPlaceholder ? isPlaceholder : 'rgba(0, 92, 135, 0.3)'};
  }
`;

const SurvivorAverage = styled.div`
  width: ${({width}) => width ? width : '47%'};
  display: flex;
  flex-direction: column;
  margin-top: ${({ isMargin }) => isMargin ? isMargin : '25px'};
  ${'' /* margin-top:25px; */}
  > input {
    height: ${({ isHeight }) => isHeight ? isHeight : '60px'};
  }

  @media (max-width: 992px) {
    width: 45% !important;
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SurvivorChallengeSection = styled.div`
  width: 100% !important;
  display:flex;
  justify-content:space-between;
  @media (max-width: 550px) {
    width: 100% !important;
    margin-top: 15px;
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  padding: 5px 0;
  border-bottom: 1px solid #005C871A;
  cursor:${({ disabled }) => disabled ? "not-allowed" : 'pointer'};
  &:last-child{
    border:none;
  }
  > a {
    width:100%;
    display: flex;
    align-items: center;
    display: flex;
    padding-left: 15px;
    color:#005C87;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
      display:none;
    }
    &:active, &:hover, &:focus {
      outline: none;
      color:#005C87!important;
      background-color:#005C870D!important;
      border-radius:6px !important;
      font-family:"Rubik-Medium"!important;
      font-size:14px;
      line-height:20px;
      >img{
        width:16px;
        height:16px;
        display: flex;
        margin: auto;
        margin-right: 15px;
      }
    }
  }
`;

const TitleContainer = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '15px 0px 10px 0px'};
  margin: ${({ margin }) => margin};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    background:#F7F5EB80;
    color:#005C87;
    border: 1px solid #005C874D;
    background:rgba(156,156,156,.04);
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    background:#F7F5EB80;
    border: 1px solid #005C874D;
  }
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ fullWidth, widthValue }) => widthValue ? widthValue : fullWidth ? "100%" : "50%"};
    height: ${({ height }) => height ? height : '200px'};
    overflow-y: auto;
    position:absolute;
    position: ${({ position }) => position ? "relative" : "absolute"};
    padding: 0 15px;
    border: 1px solid #005C874D;
    padding:5px 15px;
    ::-webkit-scrollbar {
      width: 7px;
      height: 45px;
    }
    ::-webkit-scrollbar-thumb {
      background: #005C8799;
      cursor: pointer;
      border-radius: 2.5px;
    }
  
    ::-webkit-scrollbar-track {
      background: #005C870D;
      border-radius: 2.5px;
    }
    >li
      >a{
      display:flex;
      color: #005C8799;
      font-family:"Rubik";
      font-size:14px;
      line-height:20px;
      padding: 3px 0 3px 15px;
      }
      >.active>a:hover {
        color: #005C87;
        font-family:"Rubik-medium";
        font-size:14px;
        line-height:20px;
      }
    }

  .dropdown-menu>.active>a{
    background:#005C870D;
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    padding: 3px 0 3px 15px;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
    }
  }
  .dropdown-menu>.active>a:hover {
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    background:#F7F5EB80;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const ActivityDropdown = styled(DropdownButton)`
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: ${({notActive}) =>notActive? '#005C87' : '#005C87'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik'};
  border: 1px solid #005C874D;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:white;
  border-radius: 6px;
  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background: white;
    border: 1px solid rgba(0, 92, 135, 0.3);
    text-transform: capitalize;
    color: rgb(0, 92, 135);
    font-size: 16px;
    font-family: rubik-medium;
  }
  button{
    border: 5px solid black;
    width: 100%;
    height: 100%;
    background: none;
    color: rgba(0, 92, 135);
    border: none;
    display:flex;
    align-items:center;
    &:hover{
      > img{
        border: 1px solid black;
      }
    }
    &:hover, &:active, &:active:focus{
      background: none;
      color: rgba(0, 92, 135);
    }
    &:active{
      color: rgba(0, 92, 135);
      background: none !important;
    }
    &:focus{
      background: none !important;
      color: rgba(0, 92, 135);
    }
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    ${'' /* width: 70%; */}
    width: ${({isWidth}) => isWidth ? isWidth : '70%'};
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;

    .dropdown-item{
      color: rgba(0, 92, 135, 0.6);
      padding: 3px 0px 3px 15px;
      background: none;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      display: none;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
  }
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background:white;
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

// New Challenge Type dropdown 
const ChallengeTypeDropdown = styled(Dropdown)`
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: rgb(0, 92, 135);
  font-size:16px;
  font-family: rubik-regular;
  border: 1px solid rgba(0, 122, 177, 0.30);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background: white;
  button{
    width: 100%;
    height: 100%;
    background: none;
    color: #005C87;
    font-family: 'rubik-regular';
    border: none;
    text-align: left;
    &:hover{
      > img{
        border: 1px solid black;
      }
    }
    &:hover, &:active, &:active:focus{
      background: none;
      color: rgb(0, 92, 135);
    }
    &:active{
      color: #005C87;
      background: none !important;
    }
    &:focus{
      background: none !important;
      color: #005C87;
    }
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    width: 100%;
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;
    .dropdown-item{
      color: rgba(0, 92, 135, 0.6);
      padding: 3px 0px 3px 15px;
      background: none;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      margin: auto 15px auto auto;
      display: none;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
  }
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background: rgba(156,156,156,.04);
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

// New Activity Challenge dropdown 
const ActivityChallengeDropdown = styled(Dropdown)`
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: rgb(0, 92, 135);
  font-size:16px;
  border: 1px solid rgba(0, 122, 177, 0.30);
  border-radius: 4px;
  font-family: rubik;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  color: rgb(0, 92, 135);
  background: white;
  button{
    width: 100%;
    height: 100%;
    background: none;
    color: rgb(0, 92, 135);
    font-family:rubik;
    border: none;
    text-align: left;
    &:hover{
      > img{
        border: 1px solid black;
      }
    }
    &:hover, &:active, &:active:focus{
      background: none;
      color:rgb(0, 92, 135);
    }
    &:active{
      color: #005C87;
      background: none !important;
    }
    &:focus{
      background: none !important;
      color: #005C87;
    }
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    width: 100%;
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;
    .dropdown-item{
      color: rgba(0, 92, 135, 0.6);
      padding: 3px 0px 3px 15px;
      background: none;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      display: none;
      margin: auto 15px auto auto;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
  }
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background:white;
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

// New Challenge Point dropdown 
const ChallengePointDropdown = styled(Dropdown)`
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: #005C87;
  font-size:16px;
  font-family: rubik-regular;
  border: 1px solid rgba(0, 122, 177, 0.30);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background: white;
  color: rgb(0, 92, 135);
  button{
    width: 100%;
    height: 100%;
    background: none;
    color:#005C87;
    font-family: rubik;
    border: none;
    text-align: left;
    &:hover{
      > img{
        border: 1px solid black;
      }
    }
    &:hover, &:active, &:active:focus{
      background: none;
      color: rgba(0, 92, 135);
    }
    &:active{
      color: #005C87;
      background: none !important;
    }
    &:focus{
      background: none !important;
      color: #005C87;
    }
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    ${'' /* width: 70%;
     */}
    width: ${({isWidth}) => isWidth ? isWidth : '70%'};
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;
    .dropdown-item{
      color: rgba(0, 92, 135, 0.6);
      padding: 3px 0px 3px 15px;
      background: none;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      display: none;
      margin: auto 15px auto auto;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
  }
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background:rgba(156,156,156,.04);
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

// New Challenge Point dropdown 
const ChallengeSurveyDropdown = styled(Dropdown)`
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: #005C87;
  font-size:16px;
  font-family: rubik;
  border: 1px solid rgba(0, 122, 177, 0.30);
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background: white;
  color: rgba(0, 92, 135);
  button{
    width: 100%;
    height: 100%;
    background: none;
    color: rgb(0, 92, 135);
    font-family:rubik;
    border: none;
    text-align: left;
    &:hover{
      > img{
        border: 1px solid black;
      }
    }
    &:hover, &:active, &:active:focus{
      background: none;
      color: rgba(0, 92, 135);
    }
    &:active{
      color: #005C87;
      background: none !important;
    }
    &:focus{
      background: none !important;
      color: #005C87;
    }
    &::after{
      display: none;
    }
  }
  .dropdown-menu{
    width: 100%;
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;
    .dropdown-item{
      color: rgba(0, 92, 135, 0.6);
      padding: 3px 0px 3px 15px;
      background: none;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      display: none;
      margin: auto 15px auto auto;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
  }
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background: white;
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

const FieldTitle = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 16.5px;
  color: #005C87;
  font-family: 'Rubik';
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
  >span{
    color:#F6797D;
    font-size: 18px;
  }
`;

const CustomTextArea = styled.textarea`
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Regular";
  padding: ${({ padding }) => padding ? '0 15px' : '15px'};
  border-radius: 4px;
  ${'' /* border: 1px solid #005C874D; */}
  border: ${({ isBorder }) => isBorder ? isBorder : '1px solid #005C874D'};
  width: 100%;
  resize: none;
  color: #005C87;
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: ${({ height }) => height ? height : '165px'};
  ${'' /* background: #F7F5EB80; */}
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #005C8799;
    opacity: 1;
    font-family: "Rubik";
  }

  :-ms-input-placeholder {
    color: #005C8799;
  }

  ::-ms-input-placeholder {
    color: #005C8799;
  }
`;

const Steps = styled.div`
display: flex;
width: 100%;
flex-wrap:wrap;
.wrapper{
    width:100%;
    display:flex;
.number{
  width:40px;
  height:40px;
  background: ${({ active, activeColor }) => activeColor? '#005C87' :active ? "#69C2FF" : '#005C870D'};
  margin-right: 25px; 

  font-family: ${({ active, activeColor }) => activeColor?'Rubik-Medium': active ? "Rubik-Medium" : 'Rubik-Medium'};
  font-size: 24px;
  line-height: 24px;
  color: ${({ active, activeColor }) => activeColor?'white': active ? "white" : '#005C87'};
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius:3px;
}
.heading{
    font-family: ${({ active,activeColor }) => activeColor ? "Rubik-Medium": active ? "Rubik-Medium" : 'Rubik'};
    font-size: 18px;
    line-height: 24px;
    color: ${({ active,activeColor }) => activeColor ? "#005C87": active ? "#0D4270" : 'rgba(0, 92, 135, 0.6)'};
    align-items: center;
    display: flex;
}
}
.arrow{
    width:100%;
    display:flex;
    .active{
        width: 40px;
        height:40px;
        display:flex;
        justify-content:center;
        margin:15px 0px;
        >img{
            width: 10px;
            height: 36px;
        }
    }
}
`;

export { CustomInput, SurvivorAverage, SurvivorChallengeSection, CustomMenuItem, TitleContainer, ActivityDropdown, FieldTitle, CustomTextArea, Steps, ChallengeTypeDropdown, ActivityChallengeDropdown, ChallengePointDropdown, ChallengeSurveyDropdown };